<template>
  <b-modal
    id="modal-change-group"
    ref="modal-change-group"
    :title="$t('customize.change_group')"
    modal-class="modal-secondary"
    size="lg"
    scrollable
    v-if="selected_data.ids"
    cancel-variant="outline-secondary"
    :ok-title="$t('customize.move')"
    :cancel-title="$t('forms.cancel')"
    centered
    @ok="handleOk"
    @close="clearForm"
    @cancel="clearForm"
    :hide-footer="ownDreamers.length<=0"
  >
      <div v-if="ownDreamers.length>0">
        <b-card-text>
          {{$t('customize.change_group_explanation')}}
        </b-card-text>
        
        <b-row>
          <b-col>
            <b-form>
              <!-- GROUP -->
              <b-form-group
                :label="$t('fields.group')"
                label-for="group"
              >
                <v-select
                  v-model="group"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :getOptionLabel="group => group.name"
                  :options="groups"               
                />
                <span v-if="show_error" style="color:red">{{ $t('customize.select_group') }}</span>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h5 class="mt-1" >{{$t('customize.children_with_rule')}}</h5>
            <b-avatar-group
                class="mt-2 pt-50 mb-1"
                size="33"
            >
              <b-avatar
                v-for="dreamer in ownDreamers"
                :key="dreamer.id"
                v-b-tooltip.hover.bottom="dreamer.name"
                :src="require('@/assets/images/avatar/AvatarSprite_' + (dreamer.avatar || 57) + '.png?v4')"
                class="pull-up"
                variant="light"
              />
            </b-avatar-group>
            <b-card-text>
              {{$t('customize.change_group_owned_only')}}
            </b-card-text>
          </b-col>
        </b-row>
      </div>
      <b-card v-else bg-variant="warning" class="mt-1" text-variant="white">
        <b-card-title class="text-white">
         {{$t('customize.change_group_owned_only')}}
        </b-card-title>
       
      </b-card>
      
    </b-modal>
</template>

<script>
import { moveGroup } from '@/api/routes'

import { BRow, BCol, BModal, VBModal, BCard, 
  BCardText, BCardTitle, BForm, BFormGroup,
  BAvatar, BAvatarGroup, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components:{
    BRow, BCol, BModal, BForm, BFormGroup,
    BCard,
    BCardText, BCardTitle,
    BAvatar, BAvatarGroup, VBTooltip,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  props:{
    selected_data: {
      type: Object,
      required: true
    },
    groups:{
      type: Array,
      required: true
    },
  },
  data(){
    return {
      group: {},
      show_error: false
    }
  },
  computed:{
    ownDreamers(){
      let dreamers = this.selected_data.info.filter(d => d.owner)
      //// console.log(dreamers, "dddd")
      return dreamers
    }
  },
  methods:{
    clearForm(){
      this.show_error = false
      this.group={}
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      // console.log(this.group)
      if(this.group.name){
      this.show_error = false
      this.handleSubmit()
      } else {
      this.show_error = true
      }
    },
    async handleSubmit(){

      let d_ids = this.selected_data.info.filter(d => d.owner).map(x => x.id)
      let data = {
        dreamers: d_ids,  
        group: this.group.id
      }

   /*    //cerrar modal
          this.$nextTick(() => {
            this.clearForm();
            this.$refs['modal-change-group'].toggle('#toggle-btn')
          }) */

      // console.log(data, "---save---")
      await this.$http.post(moveGroup, data).then( response => {
        // console.log(response)
        if(response.data.status == 200){
          this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));
          this.$nextTick(() => {
            this.$refs['modal-change-group'].toggle('#toggle-btn')
          })
          this.$emit('refetch-data');
          this.clearForm();
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        this.$nextTick(() => {
          this.$refs['modal-change-group'].toggle('#toggle-btn')
        })
      })
    
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>