<template>
  <div v-if="!loading">
    <!-- BREADCRUMBS -->
    <custom-breadcrumb
      :pageTitle="group.id==0 ? $t('table.no_group') :group.name"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>

    <!-- FORM DE DREAMER -->
    <add-new-dreamer
      v-if="isAddNewDreamerSidebarActive"
      :preselected_group="[group]"
      :groups="own_groups"
      :nee="nee"
      :topics="topics"
      :is-add-new-dreamer-sidebar-active.sync="isAddNewDreamerSidebarActive"
      @refetch-data="getData"
    />

    <modal-personalize
      :isGroup="false"
      :selected_data="selDreamersData"
    />
    <modal-content-restriction
      :isGroup="false"
      :selected_data="selDreamersData"
    />

    <b-sidebar v-if="sidebar_lp_dreamer_id"
      :key="sidebar_lp_dreamer_id"
      :id="'sidebar-right-' + sidebar_lp_dreamer_id"
      bg-variant="white"
      right
      backdrop
      shadow
      @hidden="hideLPSidebar"
      :visible="showSidebar"
      style="z-index: 2000"
    >
      <sidebar-assigned-routes @hide="hideLPSidebar" :dreamerId="sidebar_lp_dreamer_id"/>
    </b-sidebar>

    <modal-change-group
      :groups="own_groups_with_no_group"
      :selected_data="selDreamersData"
      @refetch-data="getData"
    />

    <modal-children-passwords
      :isGroup="false"
      :selected_data="selDreamersData"
    />

    <!-- info -->
    <b-row>
      <b-col cols="12">
        <info-card
          :group="group"
          :dreamers="dreamers"
          :tutors="tutors"
        />
      </b-col>
    </b-row>

    <!-- Buttons -->
    <b-row class="mb-2">
      <b-col cols="6">
        <b-form-checkbox
          v-if="dreamers.length>0"
          v-model="allSelected"
          @change="manageSelectAll()"
          inline
        >
          {{ $t('table.select_all')}}
        </b-form-checkbox>
      </b-col>
      <b-col cols="6" style="text-align:right">
        <b-button
          v-for="button in buttonsOptions"
          :key="button.action"
          :disabled="button.disabled||false"
          v-b-tooltip.hover.top="button.name"
          :variant="button.variant||'primary'"
          type="button"
          class="ml-1 mb-1"
          @click="handle(button.action)"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
        <span v-if="button.msg">{{$t(button.msg)}}</span>
          <feather-icon v-else
            :icon="button.icon"
            size="16"
          />
        </b-button>
      </b-col>

    </b-row>

    <!-- Dreamers Cards -->
    <b-row v-if="dreamers.length == 0">
      <b-col class="text-center">
        <span class="text-muted">{{ $t('table.no_dreamers') }}</span>
      </b-col>
    </b-row>
    <b-row v-else class="match-height">
        <b-col
            lg="3"
            md="6"
            v-for="dreamer in dreamers"
            v-bind:key="dreamer.id"
        >
        <avatar-card 
          :item="dreamer"
          :dropdown_options="dropdown_options(dreamer.owner)"
          context="dreamer"
          :selected_items="selected_dreamers"
          @item-selected="itemSelected"
          @mostrar-sidebar="mostrarSidebar"

          @details="$router.push({ name: 'dreamer-view', params: { id: dreamer.id } })"
          @edit="$router.push({ name: 'dreamer-edit', params: { id: dreamer.id } })"
          @personalize="showPersonalizeModal"
          @content-restriction="showContentRestrictionModal"
          
          @passwords="$router.push({ name: 'dreamer-edit', params: { id: dreamer.id, tab:1  } })"
          @share="$router.push({ name: 'dreamer-edit', params: { id: dreamer.id, tab:4  } })"
          
          @delete="deleteModel(dreamer.id)"
          @unlinkSharedDreamer="unlinkSharedDreamer(dreamer.id)"
        />
      
        </b-col>
        
    </b-row>
  </div>
</template>

<script>
import { getGroup, deleteDreamer, deleteDreamersMasive, unlinkShared, exportGeneral } from '@/api/routes'

import AddNewDreamer from '../dreamers/AddNew.vue'
import ModalPersonalize from '../modals/ModalPersonalize.vue'
import ModalContentRestriction from '../modals/ModalContentRestriction.vue'
import ModalChangeGroup from '../modals/ModalChangeGroup.vue'
import ModalChildrenPasswords from '../modals/ModalChildrenPasswords.vue'

import InfoCard from './InfoCard.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import AvatarCard from '@/views/sl-components/AvatarCard.vue'
import SidebarAssignedRoutes from '@/views/learning-paths/components/SidebarAssignedRoutes.vue'

import {
  BFormCheckbox, VBTooltip,
  BButton, BRow, BCol, BSidebar
} from 'bootstrap-vue'
import { decryptData } from '@/auth/utils'

export default {
  components:{
    BFormCheckbox,
    BButton, BRow, BCol, BSidebar,
    SidebarAssignedRoutes,
    CustomBreadcrumb,
    AddNewDreamer,
    AvatarCard,
    InfoCard,
    ModalPersonalize,
    ModalContentRestriction,
    ModalChangeGroup,
    ModalChildrenPasswords,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return {
      loading: true,
      group: {},
      dreamers: [],
      tutors:[],
      selected_dreamers: [],
      allSelected: false,

      nee:[],
      topics:[],
      groups:[],
      own_groups:[],
      own_groups_with_no_group: [],


      isAddNewDreamerSidebarActive: false, 

      owned_dreamers: null,

      showSidebar:false,
      sidebar_lp_dreamer_id: null

    }
  },
  mounted(){
    this.getData()
  },
  computed:{
    selDreamersData(){
      //let d = this.selected_dreamers.slice(0, 5).map(x => this.dreamers.filter(e=> e.id == x))
      let d = this.selected_dreamers.map(x => this.dreamers.filter(e=> e.id == x))
      let clean_d = d.map(x=>{ return{'id': x[0].id, 'name': x[0].name, 'avatar': x[0].avatar, 'owner': x[0].owner}})
      let data = {
        ids: this.selected_dreamers,
        info: clean_d
      }
      return data
    },
    breadcrumb(){
      return [
        {
          text: this.$t('Groups'),
          to: 'groups'
        },
        {
          text: this.group.id==0 ? this.$t('table.no_group') : this.group.name,
          active: true,
        },
      ]
    },
    buttonsOptions(){
      let opt= []
      let userData = decryptData(localStorage.getItem('userData'))
      let can_create_new_dreamers = false
      
      if(userData.user_max_children == 0){
        //unlimited dreamers
        can_create_new_dreamers = true
      } else {
        if(this.owned_dreamers.length < userData.user_max_children){
          can_create_new_dreamers = true
        }
      }
      if(this.group.owner){
        opt.push({
            action: 'add-dreamer',
            icon: "PlusIcon",
            variant: !can_create_new_dreamers? "secondary":"success",
            name: this.$t('forms.add_model', {model: this.$t('models.dreamer')}),
            disabled: !can_create_new_dreamers,
            msg:!can_create_new_dreamers ? 'message.ERR_DREAMER_1003' : null
          })
        //   if(this.dreamers.length>0){
        //   opt.push({
        //       action: "exportToExcel",
        //       icon: "DownloadIcon",
        //       name: this.$t('table.export_to_excel'),
        //     }) 
        // }
      }
      if(this.selected_dreamers.length > 0){
         
          opt.push(
            {
              action: "customizeDreamers",
              icon: "SlidersIcon",
              name: this.$t('customize.customize'),
            }, 
            {
              action: "contentRestriction",
              icon: "ClockIcon",
              name: this.$t('customize.mandatory'),
            },
            {
              action: 'assignPasswords',
              name: this.$t('children_passwords.edit_children_passwords'),
              icon: "UnlockIcon"
            }
          )
        if(this.group.owner){ 
            opt.push(
            {
              action: "changeGroup",
              icon: "LogInIcon",
              name: this.$t('customize.change_group'),
            }, 
            {
              action: "deleteSelection",
              icon: "Trash2Icon",
              name: this.$t('table.delete_selection'),
              variant: "danger",
            })
        }
      }
      return opt
    },
  },
  
  methods:{
    hideLPSidebar() {
      this.showSidebar = false
    },
    mostrarSidebar(id){
      this.sidebar_lp_dreamer_id = id
      this.showSidebar = true
    },
    manageSelectAll(){
      if(this.allSelected){
        this.selected_dreamers = this.dreamers.map( i => i.id)
      } else {
        this.selected_dreamers = []
      }
    },
    dropdown_options(isOwner){
      let options = []
      /* if(isOwner){
        options.push(
          {
          action: 'edit',
          color: null,
          title: this.$t('forms.edit_model', {model: this.$t('models.dreamer')}),
          icon: "EditIcon"
        },
        )
      } */

      options.push(
        {
          action: "details",
          color: null,
          title: this.$t('table.details'),
          icon: "FileTextIcon",
        }, 
        {
          action: 'edit',
          color: null,
          title: this.$t('forms.edit_model', {model: this.$t('models.dreamer')}),
          icon: "EditIcon"
        },
        {
          action: 'personalize',
          color: null,
          title: this.$t('customize.customize'),
          icon: "SettingsIcon"
        },
        {
          action: 'content-restriction',
          color: null,
          title: this.$t('customize.mandatory'),
          icon: "ClockIcon"
        },
        {
          action: 'passwords',
          color: null,
          title: this.$t('settings.change_pin'),
          icon: "UnlockIcon"
        },
      )
      if(isOwner){
        options.push({
          action: 'share',
          color: null,
          title: this.$t('general.share'),
          icon: "Share2Icon"
        })
        options.push({
          action: 'delete',
          color: 'red',
          title: this.$t('forms.delete'),
          icon: "TrashIcon"
        })
      } else {
        if(this.group.owner){
          options.push({
            action: 'unlinkSharedDreamer',
            color: 'red',
            title: this.$t('dreamer.unlink_shared_dreamer'),
            icon: "XIcon"
          })
        }
      }
      return options
    },
    async getData(){
        this.loading = true
        await this.$http.get(getGroup+'/'+this.$route.params.id).then( response =>{
          if(response.status ==200){
            this.group = response.data.group;
            this.tutors = response.data.tutors ? response.data.tutors : [];
            this.nee = response.data.nee;
            this.topics = response.data.topics;
            this.dreamers = response.data.dreamers;
            this.groups = response.data.groups;
            this.manageSelectAll();
      
            this.owned_dreamers = response.data.all_dreamers.filter(x => x.owner && !x.deleted_at)
            let userData = decryptData(localStorage.getItem('userData'))

            let own_g = []
            let own_g_and_no_group = []
            this.groups.map(g=>{
              if(g.id != 0){
                if(g.id_user == userData.id){
                  own_g.push(g)
                  own_g_and_no_group.push(g)
                }
              }
            })
             let no_g = {
              id:0,
              id_avatar:null,
              id_user: userData.id,
              name: this.$t('table.no_group'),
              }
              own_g_and_no_group.push(no_g)
            this.own_groups = own_g;
            this.own_groups_with_no_group = own_g_and_no_group;
  
            this.loading = false
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg_code? this.$t('message.'+response.data.msg_code) : response.data.msg);
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    deleteModel(id){
      this.$bvModal
        .msgBoxConfirm(this.$t('table.delete_dreamer_confirmation'), {
          title: this.$t('forms.delete_model', {model: this.$t('models.dreamer')}),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.$http.delete(deleteDreamer+'/'+id).then( response =>{
              if(response.data.status == 200){
                this.getData()
                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));
              } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    },
    deleteSelection(){
      let dreamers_data = this.selDreamersData
      let dreamers_array = dreamers_data.info.filter(x=> x.owner).map(d=>d.id)
        this.$bvModal
          .msgBoxConfirm(this.$t('table.delete_selection_confirmation', {num: dreamers_array.length})+' '+this.$t('customize.delete_dreamer_owned_only')+' '+this.$t('table.deactivate_dreamers_recover'), {
            title: this.$t('table.delete_selection'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'dreamers':   dreamers_array
              }
              this.$http.post(deleteDreamersMasive, data).then( response =>{
                if(response.data.status == 200){
                  this.getData()
                  this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.delete_all.error'));
                }
                this.selected= []
              }).catch(error => {
                // console.log("Err -> ", error)
              })
            }
          })
      
    },
    unlinkSharedDreamer(dreamer_id){
      this.$bvModal
        .msgBoxConfirm(this.$t('dreamer.unlink_shared_dreamer_confirmation'), {
          title: this.$t('dreamer.unlink_shared_dreamer'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              id: dreamer_id,
              context: 'dreamer'
            }
            this.$http.post(unlinkShared, data).then( response =>{
              if(response.data.status == 200){
                this.getData()
                this.makeToast('success', this.$t('Success'), this.$t('message.'+response.data.msg_code));
              } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
              this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            })
          }
        })
    },
    itemSelected(item){
      var index = this.selected_dreamers.indexOf(item.id);
      if (index !== -1) {
        this.selected_dreamers.splice(index, 1);
      } else {
        this.selected_dreamers.push(item.id)
      }  

      if(this.selected_dreamers.length == this.dreamers.length){
        this.allSelected = true
      } else {
        this.allSelected = false
      }
    
    },
    showPersonalizeModal(dreamer){
      this.selected_dreamers = []
      this.itemSelected(dreamer)
      this.$bvModal.show('modal-personalize')
    },
    showContentRestrictionModal(dreamer){
      this.selected_dreamers = []
      this.itemSelected(dreamer)
      this.$bvModal.show('modal-content-restriction')
    },
    handle(action){
      switch (action) {
        case 'add-dreamer':
          this.isAddNewDreamerSidebarActive = true
          break;
        case 'customizeDreamers':
          this.$bvModal.show('modal-personalize')
          break;
        case 'contentRestriction':
          this.$bvModal.show('modal-content-restriction')
          break;
        case 'changeGroup':
          this.$bvModal.show('modal-change-group')
          break;
        case 'assignPasswords':
          this.$bvModal.show('modal-assign-pass')
          break;
        case 'exportToExcel':
          this.download()
          break;
        case 'deleteSelection':
          this.deleteSelection()
          break;
        default:
          break;
      }
    },
    async download(){
      let rows_ids = this.selected_dreamers.length > 0 ? this.selected_dreamers : this.dreamers.map(x => x.id)
      let data = {
        rows_ids : rows_ids,
        context : 'school-dreamers',
      }
      await this.$http.post(exportGeneral, data, {responseType:'blob'}).then( response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'Dreamers.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
  }
}
</script>

<style>

</style>