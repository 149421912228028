<template>
  <b-card>

    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="require('@/assets/images/avatar/groups/'+(group.id_avatar||'null')+'.png?v4')"

            :text="avatarText(group.name)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-1 mr-1 break-word" style="display: inline-block;">
                {{ group.name }}
              </h4>
                <b-badge style="display:inline-block;" v-if="!group.owner" pill variant="warning">{{$t('dreamer.shared')}}</b-badge>
            </div>
            <div class="d-flex flex-wrap">
              <template v-if="$can('update', 'Groups') &&  canEdit">
              <!-- group.id !=0 && group.owner && -->
                <b-button
                  v-b-tooltip.hover.top="$t('forms.edit')"
                  class="mr-1 mb-1"
                  :to="{ 
                    name: 'group-edit', 
                    params: { 
                      id: group.id, 
                    } 
                  }"
                  variant="primary"
                  v-if=" !group.deleted_at"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                  <span class="d-none d-md-inline ml-1">{{ $t('forms.edit')}}</span>
                </b-button>
              </template>
              <template v-if="group.owner && $can('delete', 'Groups')">
                <b-button
                  variant="outline-danger"
                  type="button"
                  v-b-tooltip.hover.top="$t('forms.delete')"
                  class="mr-1 mb-1"
                  v-if="group.id !=0 && !group.deleted_at"
                  @click="deleteModel"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span class="d-none d-md-inline ml-1">{{ $t('forms.delete')}}</span>
                </b-button>
              </template>
              
              <b-button
                variant="outline-secondary"
                type="button"
                v-b-tooltip.hover.top="$t('forms.go_back')"
                class="mr-1 mb-1"
                @click="hasHistory() 
                  ? $router.go(-1) 
                  : $router.push({ name: 'groups' })"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                  />
                  <span class="d-none d-md-inline ml-1">{{ $t('forms.go_back') }}</span>
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        sm="6"

      >
        <table class="mt-2 w-100">
          
          <tr>
            <th class="pb-50">
              <b-link
                v-if="group.owner && group.id !=0" 
                :to="{ name: 'group-edit', params: { id: group.id, tab:2 }  }"
                class="font-weight-bold d-block text-nowrap"
              >
                <feather-icon
                  icon="SmileIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t('Dreamers')}}</span>
              </b-link>
              <div v-else>
                <feather-icon
                  icon="SmileIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t('Dreamers')}}</span>
              </div>

            </th>
            <td class="pb-50">
              {{ dreamers.length  }}
            </td>
          </tr>
          <tr v-if="group.id !=0">
            <th class="pb-50">
              <b-link 
                v-if="group.owner"
                :to="{ name: 'group-edit', params: { id: group.id, tab:1 }  }"
                class="font-weight-bold d-block text-nowrap"
              >
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t('forms.linked_users')}}</span>
              </b-link>
              <div v-else>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span  class="font-weight-bold">{{$t('forms.linked_users')}}</span>
              </div>
            </th>
            <td class="pb-50">
              {{ tutors.length  }}
            </td>
          </tr>
        
         <tr v-if="group.created_at">
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('fields.created_at')}}</span>
            </th>
            <td>
              {{ new Date(group.created_at).toLocaleDateString() }}
            </td>
          </tr>
          <!-- <tr v-if="group.updated_at">
            <th>
              <feather-icon
                icon="EditIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('dash.cards.last_update')}}</span>
            </th>
            <td>
              {{ new Date(group.updated_at).toLocaleDateString() }}
            </td>
          </tr> -->
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { deleteGroup } from '@/api/routes'
import {
  BCard, BButton, BAvatar, BRow, BCol,VBTooltip, BLink, BBadge
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BLink, BBadge
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    dreamers: {
      type: Array,
      required: true,
    },
    tutors: {
      type: Array,
      required: true,
    },
    canEdit:{
      type: Boolean,
      required: false,
      default: true
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed:{
    dreamers_length(){
      if(this.group.id == 0) return this.dreamers.length
      
      return this.dreamers.filter(item => item.linked).length
    }
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    deleteModel(){
      this.$bvModal
        .msgBoxConfirm(this.$t('forms.delete_group_confirmation'), {
          title: this.$t('forms.delete'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){

            this.$http.delete(deleteGroup+'/'+this.group.id).then( response =>{
              // console.log(response)
              if(response.data.status == 200){
                //this.$emit('refetch-data')
                this.$router.push({ name: 'groups' });
                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

              } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>
