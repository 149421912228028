<template>
  <b-card
      no-body
      :class="['card-developer-meetup', isReport?'pointer':'']"
      @click="isReport?$emit('item-selected', item):null"
  >
      <b-link
        :to="isReport?{}:{ name: context+'-view', params: { id: item.id } }"
      > 
        <div class="bg-light-primary rounded-top text-center">
          <b-img
              :src="resolveAvatar"
              :alt="item.name"
              style="width:50%"
          />
        </div>
      </b-link>

      <b-card-body>
        <!-- header -->
        <div class="meetup-header d-flex align-items-center justify-content-between" style="width: 100%;">
          <div>
            <b-form-checkbox
              v-model="isItemChecked"
              @change="$emit('item-selected', item)"
            />
          </div>
          <b-card-title class="mb-25 text-primary alert-link text-truncate ">
              <span v-if="isReport">{{ item_name }}</span>
              <b-link
                v-else
                :to="{ name: context+'-view', params: { id: item.id } }"
              > {{ item_name }}
              </b-link>
          </b-card-title>
          <b-nav-item-dropdown
            v-if="dropdown_options.length > 0"
            id="my-nav-dropdown"
            text=""
            toggle-class="nav-link-custom"
            right
            dropup
            style="position: relative; z-index: 1031;"
          >
            <b-dropdown-item 
            v-for="(option, index) in dropdown_options" 
            :key="index" 
            :disabled="option.disabled || false "
            @click="$emit(option.action, item)" >
            <span :style="'color:' +option.color || '#6e6b7b'">
              <feather-icon
                  :icon="option.icon"
                  size="16"
                  class="mr-1"
              />{{option.title}}</span></b-dropdown-item>
          </b-nav-item-dropdown>
          <span v-else></span>
            
        </div>
        <!--/ header -->

        <!-- avatar group -->
        <b-avatar-group
            class="mt-1 pt-50"
            size="33"
            v-if="item.linked && item.linked.length > 0"
        >
            <b-avatar
              :rounded="context=='dreamer'"
              v-for="avatar in item.linked.slice(0, 5)"
              :key="avatar.id"
              v-b-tooltip.hover.bottom="avatar.name"
              :src="resolveLinkedAvatar(avatar)"
              class="pull-up badge-light"
              :to="isReport?{}:{ name: resolveAvatarGroupLink+'-view', params: { id: avatar.id } }"
            />
            <h6 class="align-self-center cursor-pointer ml-1 mb-0" v-if="item.linked.length > 5">
              {{ '+' + (item.linked.length - 5) }}
            </h6>
            </b-avatar-group>

        <template v-else>
          <b-avatar-group
              class="mt-1 pt-50"
              size="33"
              v-if="context == 'dreamer'"
          >
              <b-avatar
                :rounded="context=='dreamer'"
                key="0"
                v-b-tooltip.hover.bottom="$t('table.no_group')"
                :src="require('@/assets/images/avatar/groups/null.png?v4')"
                class="pull-up badge-light"
                :to="isReport?{}:{ name: 'group-view', params: { id: 0 } }"
              />
              
          </b-avatar-group>
        </template>
        <div v-if="(item.pin && item.pin.length > 0) || (item.password && item.password.length > 0)" style="display: flex; justify-content: flex-end;">
          <div v-b-popover.hover.bottom.html="imageTooltip(item.pin || item.password)" style="background-color: #87BCED; border-radius: 50%; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; margin-top: -35px;">
            <feather-icon icon="UnlockIcon" style="color: white;"/>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top:10px;">
          <b-badge pill variant="primary"  
            v-if="item.learnign_path"  
            @click="$emit('mostrar-sidebar', item.id)" 
            class="cursor-pointer" 
            v-b-tooltip.hover.bottom="$t('see_more')">
              {{$t('assigned_rutes')}}
          </b-badge>
          <span v-else></span>
          <b-badge pill variant="warning" v-show="item.id != 0 && !item.owner">{{$t('dreamer.shared')}}</b-badge>
        </div>
      </b-card-body>
  </b-card>
</template>

<script>
import {
    BLink,
    BCard, BImg, BCardBody,BCardTitle,BFormCheckbox,BBadge,
    BNavItemDropdown,BDropdownItem,BAvatarGroup,
    BAvatar,  VBTooltip, VBPopover, BPopover
} from 'bootstrap-vue'
export default {
  components:{
    BLink,
    BCard, BImg, BCardBody,BCardTitle,BFormCheckbox,BBadge,
    BNavItemDropdown,BDropdownItem,BAvatarGroup,
    BAvatar, BPopover
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,

  },
  props:{
    item:{
      type: Object,
      required: true
    },
    dropdown_options:{
      type: Array,
      required: true
    },
    context:{
      type: String, //"dreamer", "group"
      required: true
    },
    selected_items:{
      type: Array,
      required: true
    },
    isReport:{
      type: Boolean,
      required: false,
      default: false,
    }
    /* checked:{
      type: Boolean,
      required:false,
      default: false
    } */
  },
  data(){
    return {
      //
      item_checked : false
    }
  },
  computed:{
    item_name(){
      if(this.item.id==0){
        return this.$t('table.no_group')
      }else{
        return this.item.name
      }
    },
    resolveAvatar(){
      let avatar = '';
      switch (this.context) {
        case 'dreamer':
          avatar = require('@/assets/images/avatar/AvatarSprite_'+(this.item.avatar||'57')+'.png?v4')
          break;
        case 'group':
          if (this.item.id<=0) {
            avatar = require('@/assets/images/avatar/groups/null.png?v4')          
          } else{
            avatar = require('@/assets/images/avatar/groups/'+(this.item.id_avatar||'null')+'.png?v4')
          }
          break;
        default:
          break;
      } 
      return avatar;
    },
    resolveAvatarGroupLink(){
      let link = '';
      switch (this.context) {
        case 'dreamer':
          link = 'group'
          break;
        case 'group':
          link = 'dreamer'
          break;
        default:
          break;
      } 
      return link;
    },
    isItemChecked:{
      get: function(){
        /* 
        En algunos componentes se manda el listado de items seleccionados
        como un array de ids, y en otros casos como array de objetos.
         */
        if(typeof this.selected_items[0] == 'number'){
          //// console.log('IS NUMBRER')
          var index = this.selected_items.indexOf(this.item.id)
        } else {

          let sel_dreamers_ids = this.selected_items.map(x => x.id)
        var index = sel_dreamers_ids.indexOf(this.item.id);
        }
        //// console.log(index, "INDEX")

        return index >= 0;
      },
      set: function(value){
        this.item_checked = value
      }

    }
  },
  methods:{
    imageTooltip(pinArray) {
      return pinArray.map(pin => `<img src="${require('@/assets/images/passwords/' + pin + '.png')}" style="width: 35px; height: 35px;" />`).join('');
    },
    resolveLinkedAvatar(link_avatar){
      let avatar = '';
      switch (this.context) {
        case 'group':
          avatar = require('@/assets/images/avatar/AvatarSprite_'+(link_avatar.avatar||'57')+'.png?v4')
          break;
        case 'dreamer':
          if (link_avatar.id==0) {
            avatar = require('@/assets/images/avatar/groups/null.png?v4')          
          } else{
            avatar = require('@/assets/images/avatar/groups/'+(link_avatar.id_avatar||'null')+'.png?v4')
          }
          break;
        default:
          break;
      }
      return avatar;
    },
    
  }
}
</script>

<style>

</style>